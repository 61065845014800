import React from "react"
import explainImage1 from '@/images/roid05_l.png'
import explainImage2 from '@/images/roid05_r.png'
import explainImage3 from '@/images/roid05_ya.png'
import explainImage4 from '@/images/roid05_b.png'
import styles from "./roid05.module.css"

const Roid05 = () => (
  <section className={styles.roid05}>
    <div class="container">
      <div className={styles.box + ' box'}>
        <div className={styles.item + ' ' + styles.row + ' ' + styles.t1 + ' item row'}>
          <h2>固定電話、スマートフォンから日時指定の予約ができる</h2>
        </div>
        <div className={styles.item + ' ' + styles.row + ' ' + styles.t2 + ' item row'}>
          <div className={styles.rowtana_1}>
            <div className={styles.rowtana_2 + ' ' + styles.top}>
              <h3>電話予約システム</h3>
            </div>
            <div className={styles.rowtana_2 + ' ' + styles.bot}>
              <div className={styles.rowtana_3 + ' ' + styles.t1}>
                <h3>全て自動音声で対応</h3>
              </div>
              <div className={styles.rowtana_3 + ' ' + styles.t2}>
                <h3>
                  自動音声の案内に従って番号を<br />
                  選択するだけで予約が完了
                </h3>
              </div>
              <div className={styles.rowtana_3 + ' ' + styles.t3}>
                <img src={explainImage1} />
              </div>
            </div>
          </div>
          <div className={styles.rowtana_1}>
            <div className={styles.rowtana_2 + ' ' + styles.top}>
              <h3>WEB予約</h3>
            </div>
            <div className={styles.rowtana_2 + ' ' + styles.bot}>
              <div className={styles.rowtana_3 + ' ' + styles.t1}>
                <h3>
                  音声案内の途中でSMSによる<br />
                  WEB予約も選択可能
                </h3>
              </div>
              <div className={styles.rowtana_3 + ' ' + styles.t2}>
                <h3>
                  SMSでURLを送信し<br />
                  WEB予約ページへ誘導できる
                </h3>
              </div>
              <div className={styles.rowtana_3 + ' ' + styles.t3}>
                <img src={explainImage2} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.item + ' ' + styles.row + ' ' + styles.t3 + ' item row'}>
          <div className={styles.rowtana_1}>
            <div className={styles.rowtana_2 + ' ' + styles.t1}>
              <h3>
                予約をする際にお客様から取得する情報は電話番号のみ<br />
                個人を特定する情報を一切取得しません
              </h3>
              <p>※予約情報は決まった時間にシステムが自動で削除します</p>
            </div>
            <div className={styles.rowtana_2 + ' ' + styles.t2}>
              <h3>
                予約時間の変更・取り消しも可能
              </h3>
            </div>
            <div className={styles.rowtana_2 + ' ' + styles.t3}>
              <div className={styles.rowtana_3}>
                <img src={explainImage3} />
              </div>
            </div>
            <div className={styles.rowtana_2 + ' ' + styles.t4}>
              <div className={styles.rowtana_3}>
                <h3>
                  お客様が来られたら<br />
                  予約された電話番号を照合するだけ
                </h3>
              </div>
              <div className={styles.rowtana_3}>
                <img src={explainImage4} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Roid05
