import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Roid01 from "../components/index/roid01"
import Roid02 from "../components/index/roid02"
import Roid03 from "../components/index/roid03"
import Roid04 from "../components/index/roid04"
import Roid05 from "../components/index/roid05"
import Roid06 from "../components/index/roid06"
import Roid10 from "../components/index/roid10"

import "./index.css"

const IndexPage = () => (
  <>
    <Layout path={'root'}>
      <SEO title="Home" />
    </Layout>
    <Roid01 />
    <Roid02 />
    <Roid03 />
    <Roid04 />
    <Roid05 />
    <Roid06 />
    <Roid10 />
    
    <div class="clear"></div>   
  </>
)

export default IndexPage
