import React from "react"
import explainImage5 from '@/images/roid06_b.png'

import styles from "./roid06.module.css"

const Roid06 = () => (
  <section className={styles.roid06}>
    <div class="container">
      <div className={styles.box + ' box'}>
        <div className={styles.item + ' ' + styles.row + ' ' + styles.t1 + ' item row'}>
          <h2>予約状況の確認ができる</h2>
        </div>
        <div className={styles.item + ' ' + styles.row + ' ' + styles.t2 + ' item row'}>
          <div className={styles.rowtana_1}>
            <div className={styles.rowtana_2 + ' ' + styles.t1}>
              <h3>
                窓口の職員様は管理PCから、<br />
                予約状況の確認が可能です
              </h3>
            </div>
            <div className={styles.rowtana_2 + ' ' + styles.t2}>
              <img src={explainImage5}  />
            </div>
          </div>
        </div>
        <div className={styles.item + ' ' + styles.row + ' ' + styles.t3 + ' item row'}>
          <div className={styles.rowtana_1}>
            <h3>
              <span>予約システム</span>で申請者の数と時間を<span>把握</span>できるので、<br />
              <span>窓口</span>での<span>混雑混乱</span>を<span>防ぎます！</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Roid06
