import React from "react"

import styles from "./roid10.module.css"

const Roid10 = () => (
  <section className={styles.roid10}>
    <div class="container">
      <div className={styles.box + ' box'}>
        <div className={styles.item + ' ' + styles.row + ' item row'}>
          <div class="button">
            <a href="https://kotetsu-japan.co.jp/contact/">お問い合わせはコチラ</a>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Roid10
