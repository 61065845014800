import React from "react"
import eyeCatchImage from '@/images/roid1_word.png'

import styles from "./roid01.module.css"

const Roid01 = () => (
  <section className={styles.roid01}>
    <div className={styles.container + ' container'}>
      <div class="box">
        <div class="item col">
          <div class="coltana_1">
            <img src={eyeCatchImage} />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Roid01
