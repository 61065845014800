import React from "react"
import yoyakuRoidLogoImage from '@/images/roid2_title.png'

import styles from "./roid02.module.css"

const Roid02 = () => (
  <section className={styles.roid02}>
    <div class="container">
      <div className={styles.box + ' box'}>
        <div class="item col">
          <div className={styles.coltana_1}>
            <p>そんな<span>問題</span>をシンプルに<span>解決！</span></p>
          </div>
          <div className={styles.coltana_2}>
            <p>それが<img src={yoyakuRoidLogoImage} />です!!</p>
          </div>
          <div  className={styles.coltana_3}>
            <p>予約ロイドは<span>電話</span>を<span>掛けるだけ！</span>あとは<span>自動音声</span>が<span>案内</span>してくれます！</p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Roid02
