import React from "react"
import yoyakuRoidIconImage from '@/images/roid3_icon.png'
import menImage from '@/images/roid3_mens.png'

import styles from "./roid03.module.css"

const Roid03 = () => (
  <section className={styles.roid03}>
    <div class="container">
      <div className={styles.box}>
        <div className={styles.itemrow + ' item' + ' row'}>
          <div className={styles.rowtana_1}>
            <div className={styles.rowtana_2}>
              <img src={yoyakuRoidIconImage} />
              <h3>POINT!</h3>
            </div>
            <div className={styles.rowtana_3}>
              <p>
                <span>自動音声</span>による案内なので<br />
                <span>高齢者</span>にも安心！<br />
                しかも<span>オペレーター不要</span><br />
                なので<span>低コスト！</span>
              </p>
            </div>
          </div>
          <div className={styles.rowtana_1}>
            <div className={styles.rowtana_2}>
              <img src={yoyakuRoidIconImage} />
              <h3>POINT!</h3>
            </div>
            <div className={styles.rowtana_3}>
              <p>
                <span>web予約</span>にも<span>誘導可能！</span><br />
                <span>ユーザー</span>が<span>予約</span>にかかる<br />
                <span>時間</span>も<span>圧縮！</span>
              </p>
            </div>
          </div>
          <div className={styles.rowtana_1}>
            <div className={styles.rowtana_2}>
              <img src={yoyakuRoidIconImage} />
              <h3>POINT!</h3>
            </div>
            <div className={styles.rowtana_3}>
              <p>
                <span>電話番号</span>のみで<span>予約！</span><br />
                <span>個人情報</span>は一切<br />
                <span>取得・記録しません。</span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.itemrow + ' item' + ' row'}>
          <div className={styles.rowtana_1}>
            <div className={styles.rowtana_2}>
              <img src={yoyakuRoidIconImage} />
              <h3>POINT!</h3>
            </div>
            <div className={styles.rowtana_3}>
              <p>
                <span>複雑</span>な操作、<span>設定</span>も<span>不要！</span><br />
                <span>簡単</span>に使えます！
              </p>
            </div>
          </div>
          <div className={styles.rowtana_1}>
            <div className={styles.rowtana_2}>
              <img src={yoyakuRoidIconImage} />
              <h3>POINT!</h3>
            </div>
            <div className={styles.rowtana_3}>
              <p>
                日毎の<span>予約数</span>も<span>制限</span>できる<br />
                ので、<span>来客数</span>の<br />
                <span>コントロール</span>も<span>可能！</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.box}>
        <div className={styles.itemrow + ' item' + ' row'}>
          <img src={menImage} />
        </div>
      </div>
    </div>
  </section>
)

export default Roid03
