import React from "react"
import saraniImage from '@/images/roid3_sarani.png'

import styles from "./roid04.module.css"

const Roid04 = () => (
  <section className={styles.roid04}>
    <div class="container">
      <div className={styles.box + ' box'}>
        <div className={styles.itemrow + ' item' + ' row'}>
          <img src={saraniImage} />
        </div>
        <div className={styles.itemcol + ' item' + ' col'}>
          <h2><span>様々</span>な<span>カスタマイズ</span>にも<span>対応可能</span>です！</h2>
          <p>
            汎用的な予約システムと異なり、極力お客様のご要望に応じた機能の改変や、設定の変更など、<br />
            柔軟にご対応するための開発スタッフが対応に当たりますので、お問い合わせフォームからご要望などを<br />
            いただければ、できるだけお客様の事情や用途に応じた、柔軟な改善や機能追加を行ってまいります。
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Roid04
